import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import Layout from "../components/Layout"


const PageContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    margin-top: 8vh;

    @media (max-width: 420px) {
      flex-direction: column-reverse;
    }
`;

const ContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 60%;

    @media (max-width: 420px) {
      width: 100%;
    }
`;

const Bodytext = styled.div`
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 1em;

    width: 80%;

    letter-spacing: 0.04em;
    line-height: 1.6em;
    color: #000;

    @media max-width(420px){
      width: 100%;
    }
`;

const ImageColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    width: 40%;
    height: 80vh;

    background: url();
    background-position: center;
    background-image: cover;
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 420px) {
      width: 100%;
      height: 40vh;
    }
`;

const Subtitle = styled.h2`
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.5em;
    
    letter-spacing: 1.8px;
    color: #ff6666;

    font-style: normal;
    font-stretch: normal;
    line-height: normal;

    margin: 0;

    text-transform: uppercase;

    @media (max-width: 420px){
        font-size: 1.3em;
    }
`;

const Title = styled.h1`
    font-family: 'Poppins';
    font-weight: 500;

    color: #003399;

    font-size: 2.7em;
    letter-spacing: 1px;

    margin: 8vh 0 4vh 0;

    @media (max-width: 420px){
        font-size: 2.4em;
        margin: 48px 0 24px 0;
    }
`;

export default () => (
  <Layout>
    <PageContent>
      <ContentColumn>
        <Subtitle>
          Opps! 
        </Subtitle>
        <Title>
          Site under construction
        </Title>
        <Bodytext>
          Thank you for your interest! Apologies for the 404 error, but the remainder of this site is still being pieced together. Please hang tight and check back here soon for more content!
          <br/><br/>
          <Link to="/">Back to the homepage</Link>
        </Bodytext>
      </ContentColumn>
      <ImageColumn/>
    </PageContent>
  </Layout>
)